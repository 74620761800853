import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import RightIconCircle from "../images/right-cricle-icon";
import ServiceTab from "./ServiceTab";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import SwiperCore, { Navigation } from 'swiper';
import dayjs from "dayjs";
SwiperCore.use([Navigation]);


const createMarkup = (content) => {
    return { __html: content };
};

const SwiperTabs = ({light, data, recentPosts, recentPosts1, recentPosts2, tabsData1, normal}) => {
    const imageData = useStaticQuery(graphql`
    query {
      dynamicImages: allFile(
        filter: {
          relativePath: {
            regex: "/^(our-ui-ux)/"
          }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `);
  const imagesByPath = {};
  imageData.dynamicImages.edges.forEach(({ node }) => {
    const imagePath = node.relativePath;
    const imageData = getImage(node.childImageSharp);
    imagesByPath[imagePath] = imageData;
  });
  const imagePaths = data.images?.map((image) => {
    const imageNode = imageData.dynamicImages.edges.find(
      ({ node }) => node.relativePath === image
    );
    if (imageNode) {
      return imageNode.node.relativePath;
    }
    return null;
  });
    const tabs = data.tabs;
    const [swiper, setSwiper] = useState(null);
    const [tabIn, setTabIn] = useState(0);
    const swiperRef = useRef(null);
    useEffect(() => {
        if (swiperRef.current && swiper === null) {
            setSwiper(swiperRef.current.swiper);
        }
    }, [swiper]);
    const onChangeTab = (data) => {
        const tabIndex = tabs.indexOf(data);
        if (swiper) {
            swiper.slideTo(tabIndex);
        }
    };
    const onSlideChange = () => {
        if (swiper) {
            const currentIndex = swiper.realIndex;
            setTabIn(currentIndex);
        }
    };
    // Dates
    const formattedDates = recentPosts?.map((post) => {
        const dateStr = post.node.date;
        const dateTime = dayjs(dateStr);
        const formattedDate = dateTime.format("MMM D, YYYY");
        return formattedDate;
    });
    return (
        <section className={`project-section lg:py-[200px] md:pb-[125px] md:pt-[140px] overflow-hidden relative z-[1] pt-[100px] pb-[80px] bg-[length:100%] ${light !== undefined ? 'bg-[#FAFBFB]' : 'bg-black-main text-white'}`}>
            {
                light !== undefined ?
                <StaticImage
                    placeholder="none"
                    className="z-[-1] !absolute top-[-0px] hidden lg:block right-[0px]"
                    src="../images/guide-swiper-2.webp"
                    alt="guide"
                /> : 
                <StaticImage
                    placeholder="none"
                    className="z-[-1] !absolute top-[-450px] hidden lg:block right-[-170px]"
                    src="../images/guide-swiper.webp"
                    alt="guide"
                />
            }
            <div className='container'>
                <h2 className='font-medium mb-[20px] lg:max-w-[838px] lg:mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]' dangerouslySetInnerHTML={createMarkup(data.title)} />
                <p className='mb-[30px] font-light md:mb-[50px] lg:mb-[70px] text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] lg:max-w-[699px]'>
                    {data.des}
                </p>
                {
                    tabs.length > 0 &&
                    <ServiceTab
                        tabs={tabs}
                        activeUs={tabIn}
                        containWidth
                        mode={light ? 'dark' : ''}
                        light
                        activeThis={tabIn}
                        onChangeTab={onChangeTab}
                    />
                }
            </div>
            <div className="project-div">
                <Swiper
                    ref={swiperRef}
                    onSlideChange={onSlideChange}
                    onSwiper={(swiper) => setSwiper(swiper)}
                    className={normal !== undefined ? "project-slider view-slider container-slider industry-slider" : "project-slider view-slider industry-slider"}
                    breakpoints={{
                        100: {
                            slidesPerView: normal !== undefined ? 1 : 'auto',
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: normal !== undefined ? 1 : 'auto',
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: normal !== undefined ? 1 : 'auto',
                            spaceBetween: 50,
                        },
                    }}
                >
                    {
                        data.slides.map((slide, index) => (
                            <SwiperSlide
                                className={`industry-slider--slide ${light !== undefined ? 'light' : ''}`}
                                key={index}
                            >
                                <div className={recentPosts === undefined ? normal !== undefined ? "!bg-transparent justify-between pt-[20px] flex flex-col lg:flex-row lg:gap-[50px] gap-[30px]" : "p-[20px] md:p-[30px] lg:p-[50px] flex flex-col lg:flex-row lg:gap-[50px] gap-[30px]" : "p-[0px] md:p-[30px] lg:p-[50px] flex flex-col lg:flex-row"}>
                                    {
                                        recentPosts !== undefined ? 
                                        <>
                                            <div className="flex flex-col flex-wrap lg:flex-row gap-[20px] lg:gap-x-[50px]">
                                                {   index === 0 ?
                                                    recentPosts?.map(({ node }, index) => (
                                                        <Link to={`/${node.slug}/`} className="pb-[20px] blog-link-tag w-full gap-[20px] lg:w-[calc(50%-25px)] lg:pb-[30px] flex justify-between" key={index}>
                                                            <div className="lg:max-w-[320px]">
                                                                <h3 className="md:text-ellipsis md:line-clamp-2 lg:text-[22px] md:text-[20px] text-[18px] lg:leading-[33px] leading-[25px] md:font-semibold font-bold font-heading mb-[10px]">{node.title}</h3>
                                                                <p className="text-[13px] lg:text-[14px] leading-[22px] tracking-[.02em] font-medium lg:tracking-[.01em]">{formattedDates[index]}</p>
                                                            </div>
                                                            <div className="md:w-[137px] md:min-w-[137px] w-[97px] min-w-[97px]">
                                                                {node.featuredImage && node.featuredImage.node && (
                                                                    <>
                                                                    <GatsbyImage
                                                                        image={node.featuredImage.node.gatsbyImage}
                                                                        alt={
                                                                        node.featuredImage.node.altText
                                                                            ? node.featuredImage.node.altText
                                                                            : "blogs"
                                                                        }
                                                                        title={node.featuredImage.node.title}
                                                                    />
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Link>
                                                    )) : index === 1 ?
                                                    recentPosts1?.map(({ node }, index) => (
                                                        <Link to={`/${node.slug}/`} className="pb-[20px] blog-link-tag w-full gap-[20px] lg:w-[calc(50%-25px)] lg:pb-[30px] flex justify-between" key={index}>
                                                            <div className="lg:max-w-[320px]">
                                                                <h3 className="md:text-ellipsis md:line-clamp-2 lg:text-[22px] md:text-[20px] text-[18px] lg:leading-[33px] leading-[25px] md:font-semibold font-bold font-heading mb-[10px]">{node.title}</h3>
                                                                <p className="text-[13px] lg:text-[14px] leading-[22px] tracking-[.02em] font-medium lg:tracking-[.01em]">{formattedDates[index]}</p>
                                                            </div>
                                                            <div className="md:w-[137px] md:min-w-[137px] w-[97px] min-w-[97px]">
                                                                {node.featuredImage && node.featuredImage.node && (
                                                                    <>
                                                                    <GatsbyImage
                                                                        image={node.featuredImage.node.gatsbyImage}
                                                                        alt={
                                                                        node.featuredImage.node.altText
                                                                            ? node.featuredImage.node.altText
                                                                            : "blogs"
                                                                        }
                                                                        title={node.featuredImage.node.title}
                                                                    />
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Link>
                                                    )) :
                                                    recentPosts2?.map(({ node }, index) => (
                                                        <Link to={`/${node.slug}/`} className="pb-[20px] blog-link-tag w-full gap-[20px] lg:w-[calc(50%-25px)] lg:pb-[30px] flex justify-between" key={index}>
                                                            <div className="lg:max-w-[320px]">
                                                                <h3 className="md:text-ellipsis md:line-clamp-2 lg:text-[22px] md:text-[20px] text-[18px] lg:leading-[33px] leading-[25px] md:font-semibold font-bold font-heading mb-[10px]">{node.title}</h3>
                                                                <p className="text-[13px] lg:text-[14px] leading-[22px] tracking-[.02em] font-medium lg:tracking-[.01em]">{formattedDates[index]}</p>
                                                            </div>
                                                            <div className="md:w-[137px] md:min-w-[137px] w-[97px] min-w-[97px]">
                                                                {node.featuredImage && node.featuredImage.node && (
                                                                    <>
                                                                    <GatsbyImage
                                                                        image={node.featuredImage.node.gatsbyImage}
                                                                        alt={
                                                                        node.featuredImage.node.altText
                                                                            ? node.featuredImage.node.altText
                                                                            : "blogs"
                                                                        }
                                                                        title={node.featuredImage.node.title}
                                                                    />
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Link>
                                                    ))
                                                }
                                            </div>
                                        </> : 
                                        <>
                                            <div className="lg:max-w-[509px]">
                                                <h3 className="lg:text-[35px] md:text-[26px] text-[22px] lg:font-semibold font-medium font-heading leading-[1.3181] lg:leading-[1.5714] mb-[10px]">{slide.title}</h3>
                                                <p className="md:text-[16px] lg:text-[18px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.01em] mb-[20px] lg:mb-[30px]">{slide.desc1}</p>
                                                <p className="md:text-[16px] lg:text-[18px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.01em] mb-[20px] lg:mb-[40px]">{slide.desc2}</p>
                                                {
                                                    slide.listTile ?
                                                    <h4 className="md:text-[16px] lg:text-[18px] underline text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.01em] mb-[15px] lg:mb-[20px]">{slide.listTile}</h4> : ''
                                                }
                                                <div className="flex flex-col gap-[15px] lg:gap-[20px]">
                                                    {slide.list.map((item, index) => (
                                                        <div className="flex gap-[8px]" key={index}>
                                                            <div className="mt-[4px] md:mt-[7px] lg:mt-[7.5px]">
                                                                <RightIconCircle />
                                                            </div>
                                                            {slide.listTile ? 
                                                            <h5 className="md:text-[16px] lg:font-medium lg:text-[18px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em]">{item}</h5> :
                                                            <h4 className="md:text-[16px] lg:font-medium lg:text-[18px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em]">{item}</h4> 
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div>
                                                { 
                                                    !data.images && (
                                                    index === 0 ? tabsData1 === true ?
                                                    <StaticImage
                                                        placeholder="none"
                                                        src="../images/swiper-tab-image-6.webp"
                                                        className="w-[508px] max-w-[100%]"
                                                        alt="Our SaaS UI UX Designing Solution"
                                                        title="SaaS UX Designing"
                                                    />  :
                                                    <StaticImage
                                                        placeholder="none"
                                                        src="../images/swiper-tab-image.webp"
                                                        className="w-[508px] max-w-[100%]"
                                                        alt="Our simplified UI UX design solutions to Fintech Industry"
                                                        title="Design Solutions to Fintech "
                                                    /> : index === 1 ? tabsData1 === true ?
                                                    <StaticImage
                                                        placeholder="none"
                                                        src="../images/swiper-tab-image-7.webp"
                                                        className="w-[508px] max-w-[100%]"
                                                        alt="Enterprise UI UX Designing Solution"
                                                        text="Enterprise Designing Solution"
                                                    />  :
                                                    <StaticImage
                                                        placeholder="none"
                                                        src="../images/swiper-tab-image-2.webp"
                                                        className="w-[508px] max-w-[100%]"
                                                        alt="Our UI UX solutions to HealthTech Industry"
                                                        title="Design Solutions to HealthTech"
                                                    /> : index === 2 ? tabsData1 === true ? 
                                                    <StaticImage
                                                        placeholder="none"
                                                        src="../images/swiper-tab-image-8.webp"
                                                        className="w-[508px] max-w-[100%]"
                                                        alt="Our Research-Driven Designing Solution "
                                                        title="Research-Driven Designing"
                                                    />  :
                                                    <StaticImage
                                                        placeholder="none"
                                                        src="../images/swiper-tab-image-3.webp"
                                                        className="w-[508px] max-w-[100%]"
                                                        alt="Our tailored design solution to LogisticsTech"
                                                        title="Design Solutions to LogisticsTech"
                                                    /> : index === 3 ? tabsData1 === true ?
                                                    <StaticImage
                                                        placeholder="none"
                                                        src="../images/swiper-tab-image-9.webp"
                                                        className="w-[508px] max-w-[100%]"
                                                        alt="Our Design Thinking Approach in UI UX Designing "
                                                        title="Design Thinking Solution"
                                                    />  :
                                                    <StaticImage
                                                        placeholder="none"
                                                        src="../images/swiper-tab-image-4.webp"
                                                        className="w-[508px] max-w-[100%]"
                                                        alt="UI UX Design Solutions to MarTech Industries"
                                                        title="Design Solutions to MarTech"
                                                    /> : index === 4 ?
                                                    <StaticImage
                                                        placeholder="none"
                                                        src="../images/swiper-tab-image-5.webp"
                                                        className="w-[508px] max-w-[100%]"
                                                        alt="UI UX Design Solutions to Manufacturing Industries"
                                                        title="Design Solutions to Manufacturing"
                                                    /> : '')
                                                }
                                                {data.images && imagePaths.map((imagePath, outerIndex) => {
                                                    return (
                                                        <div key={outerIndex}>
                                                            {index === outerIndex && (
                                                                <GatsbyImage
                                                                    key={index}
                                                                    className="w-[550px] max-w-[100%]"
                                                                    image={imagesByPath[imagePath]}
                                                                    alt={outerIndex === 0 ? data.altOne : outerIndex === 1 ? data.altTwo : outerIndex === 2 ? data.altThree : data.altFour}
                                                                    title={outerIndex === 0 ? data.titleOne : outerIndex === 1 ? data.titleTwo : outerIndex === 2 ? data.titleThree : data.titleFour}
                                                                />
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </>
                                    }
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </section>
    )
}
export default SwiperTabs;
