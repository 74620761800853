import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { Link } from "gatsby";
import 'swiper/css';
import LinkArrowSvg from "../../images/link-arrow";
import '../../styles/team.css';

const TeamSection = () => {
    const dynamicImages = [
        {
            title: 'UI UX Team',
            alt: "Team at our UI UX Design Company",
            images: ['team-image-10.webp'],
            aspectRatio: '1.277 / 1',
        },
        {
            title: 'UI UX Team',
            alt: "Our UI UX Design Agency's Team",
            images: ['team-image-11.webp'],
            aspectRatio: '1.3847 / 1',
        },
        {
            title: 'UI UX Team',
            alt: "UI UX Design Team of our Design Agency",
            images: ['team-image-12.webp'],
            aspectRatio: '1.3847 / 1',
        },
    ];

    const imageData = useStaticQuery(graphql`
        query {
          dynamicImages: allFile(
            filter: {
              relativePath: { regex: "/^(team-image|visa)/" }
            }
          ) {
            edges {
              node {
                relativePath
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
    `);
    const imagesByPath = {};
    imageData.dynamicImages.edges.forEach(({ node }) => {
        const imagePath = node.relativePath;
        const imageData = getImage(node.childImageSharp);
        imagesByPath[imagePath] = imageData;
    });
    return (
        <section className="md:py-[120px] py-[80px] lg:py-[200px] bg-[#FAFBFB] relative overflow-hidden team home">
            {/* <StaticImage placeholder="none" src="../../images/guides-5.webp" alt="guides" className='guides !block md:!hidden top-[-70px] !absolute right-[-32px]' /> */}
            <div className="container relative">
                {/* <StaticImage placeholder="none" src="../../images/guides-4.webp" alt="guides" className='guides !hidden md:!block md:top-[-120px] lg:top-[-150px] !absolute md:right-[-184px] lg:right-[-124.2px]' /> */}
                <div className='flex flex-col lg:flex-row md:gap-[80px] gap-[56px] lg:gap-[34px]'>
                    <div>
                        <h2 className=' mb-[20px] lg:mb-[30px] lg:w-[482px] font-medium font-heading lg:text-[58px] md:text-[50px] text-[38px] leading-[1.36]'>We are <br className='hidden lg:block' /> Creatively Strategic </h2>
                        <p className='lg:mb-[30px] font-body md:w-[668px] lg:w-[461px] md:text-[16px] lg:text-[18px] md:leading-[1.875] lg:leading-[1.67] mb-[20px] tracking-[0.03em]'>Do you love creating revolutions? <br className='hidden md:block' />
                        Join Octet, work with design leaders, and build the next big thing.
                        </p>
                        <StaticImage placeholder="none" src="../../images/glassdoor.webp" alt="Glassdoor of our UI UX Design Agency" title='Glassdoor Review' className='glassdoor mb-[22px] lg:mb-10 md:w-[150px] w-[90px]' />
                        <Link to="/careers/" title='Join Our UI UX Design Agency' className="capitalize w-fit font-body text-[12px] lg:text-[15px] leading-[1.2] tracking-[.03em] flex items-center gap-[9px]">
                            JOIN OUR TEAM  <span className='rotate-45'><LinkArrowSvg size={15.58} fill='#2A2A2A' /></span>
                        </Link>
                    </div>
                    <div className='team-div home lg:max-w-[55vw] items-center'>
                        <Swiper
                            className="team-slider cursor-[url(../images/drag.webp),_move]"
                            breakpoints={{
                                300: {
                                    spaceBetween: 0,
                                    slidesPerView: 'auto',
                                },
                                768: {
                                    spaceBetween: 0,
                                    slidesPerView: 'auto',
                                },
                                1024: {
                                    spaceBetween: 0,
                                    slidesPerView: 'auto',
                                },
                                1200: {
                                    spaceBetween: 0,
                                    slidesPerView: 'auto',
                                },
                            }}
                            >
                            {dynamicImages.map(({ title, images, aspectRatio }, index) => {
                                return (
                                    <SwiperSlide key={index} className="!flex flex-col justify-between">
                                        <GatsbyImage className='slider-image' style={{ aspectRatio: aspectRatio}} image={imagesByPath[images[0]]} alt={title ? title : 'team'} />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default TeamSection
