import * as React from "react";
import ProjectSection from "../components/ProjectSection";
import { Link, graphql } from "gatsby";
import LinkArrowSvg from "../images/link-arrow";
import ClientsSection from "../components/home/ClientSection";
import TeamSection from "../components/home/TeamSection";
import Banner from "../components/Banner";
import SwiperTabs from "../components/SwiperTabs";
import Faq from "../components/Faq";
import Services from "../components/home/Services";
import Testimonials from "../components/Testimonials";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import ServiceTab from "../components/ServiceTab";
import { Helmet } from "react-helmet";

const Questions = [
  "How do you approach your UI UX Design project?",
  "How does your UI UX design agency differ from other companies?",
  "What UI UX design services does your UI UX Design Agency offer?",
  "What design tools do you use for UI UX design projects?",
  "Can your top UI UX design company work with existing branding & design elements?",
  "How do you estimate the UI UX project timeline?",
  "Does your UI UX Design Company offer a dedicated Designer for projects?",
  "What is the standard pricing followed by your best UI UX design company?",
];

export default function IndexPage({ data }) {
  const recentPosts = data?.recentQuery?.edges;
  const recentPosts1 = data?.recentQueryInspirations?.edges;
  const recentPosts2 = data?.recentQueryTutorials?.edges;

  const banner = {
    title: `<span class="h1-span">Research-Driven</span> <span class="h1-span">UI UX Design Agency</span>`,
    content:
      "We are a UI UX design agency that creates impactful digital solutions for SaaS, B2B, and Enterprise Products. We build beautiful, memorable, and functional product designs.",
  };
  const projectData = [
    {
      title: "Landscape Pro",
      desc: "Property Management Platform",
      Industry: "SaaS",
      firstAlt: "UI UX Design of a Schedule Screen to Manage Property",
      firstTitle: "Schedule Screen UI Design",
      secondAlt: "UI UX Design to Manage and Track User's Status",
      secondTitle: "UI Screen to Manage Users",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/landscape-pro/",
      images: ["landscape-pro.webp", "landscape-pro-2.webp", "landscape-pro-3.webp"],
      thirdSlide: {
        title: 'UI UX Designing for a SaaS Platform',
        des: `We researched user problems and experimented with various options to reach an intuitive and engaging version for software users. We also conducted a design audit to enhance the platform’s usability and offered efficient outdoor landscape management solutions.`,
        list: [
          {
            listTitle: 'Design Challenges',
            listItems: [
              'Ensuring cross-platform compatibility',
              'Simplifying complex data visualization',
              'Optimizing performance across networks',
              'Balancing aesthetics with functional clarity'
            ]
          },
          {
            listTitle: 'Design Solution',
            listItems: [
              'Implemented responsive design for versatility',
              'Prioritized user-centered design principles',
              'Utilized intuitive data visualization techniques',
              'Optimized the user interface for speed '
            ]
          }
        ]
      }
    },
    {
      title: "Elastic Sign",
      desc: "Digital Signature Platform",
      Industry: "SaaS",
      firstAlt: "UI Design to View all the Documents and their Status",
      firstTitle: "Manage Documents UI Design",
      secondAlt: "UI UX Design to Sign Documents Digitally",
      secondTitle: "UI Design to Sign Digital Documents",
      thirdAlt: "UI Screen to draw, upload or type your own signature",
      thirdTitle: "Create Your Own Signature",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/elastic-sign",
      images: ["elastic-sign.webp", "elastic-sign-2.webp", "elastic-sign-3.webp"],
      thirdSlide: {
        title: 'Agile Designing & Development solutions for SMEs',
        des: `We conducted user tests using different methods to streamline SMEs' processes. Our agile design and development solutions provided faster turnarounds, improving their overall experience. `,
        list: [
          {
            listTitle: 'SMEs Design Challenges',
            listItems: [
              'Maintaining consistency across platforms',
              'Meeting diverse customer preferences',
              'Incorporating feedback for improvements',
              'Keeping up with technological advancements'
            ]
          },
          {
            listTitle: 'Design Solution',
            listItems: [
              'Customized customer experiences',
              'Incorporated user feedback for improvements',
              'Designed templates for a streamlined process',
              'Implemented scalable design systems'
            ]
          }
        ]
      }
    },
    {
      title: "Hala Gig",
      desc: "Consumer Experience Platform",
      Industry: "SaaS",
      link: "/project/ui-ux-design-halagig/",
      firstAlt: "Mobile UI Design To Collect Customer's Response",
      firstTitle: "Feedback Collection UI Screen",
      secondAlt: "UI Design Of Agent's Account",
      secondTitle: "UI UX Design of Agent's Account",
      thirdAlt: "Mobile App UI UX Design to Onboard Agents",
      thirdTitle: "Onboarding UI UX Screen",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      images: ["hala-gig.webp", "hala-gig-2.webp", "hala-gig-3.webp"],
      thirdSlide: {
        title: 'Designing a Cross-Platform Application for a Startup',
        des: 'We used different user research techniques and applied the most suitable design strategy to deliver the best cross-platform experience to startups. Focusing on mobile-first designing and development, we delivered an accessible and seamless user experience across all platforms.',
        list: [
          {
            listTitle: 'Startup Design Challenges',
            listItems: [
              'Ensuring compatibility with various devices',
              'Integrating accessible navigation for all users',
              'Balancing aesthetics with mobile functionality',
              'Meeting accessibility standards'
            ]
          },
          {
            listTitle: 'Design Approach',
            listItems: [
              'Utilized mobile accessibility testing tools',
              'Offered adjustable font sizes and styles',
              'Prioritized responsive design principles',
              'Conducted user testing on mobile devices'
            ]
          }
        ]
      }
    },
    {
      title: "LikeHire",
      desc: "Ultimate Healthcare Job Portal",
      Industry: "HealthTech",
      link: "/project/ui-ux-design-likehire/",
      firstAlt: "Landing Page UI UX Design of a Healthcare Job Platform",
      firstTitle: "Landing Page UI Design",
      secondAlt: "UI Design of the Job Applicants Screen",
      secondTitle: "UI Screen of Job Applicants",
      thirdAlt: "Dashboard of Healthcare Job Portal Platform to Check the Application Status",
      thirdTitle: "Dashboard to Check the Application Status",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "VueJs Development",
          link: "/vuejs-development/",
        },
      ],
      images: ["like-hire.webp", "like-hire-2.webp", "like-hire-3.webp"],
      thirdSlide: {
        title: 'Web Designing for a Job Board to Boost Marketing',
        des: 'We applied a unique branding strategy to increase the brand’s presence and ensure maximum visibility. With our UX design solutions, we created a dynamic platform that attracted users to the job board and retained them, saving time and resources.',
        list: [
          {
            listTitle: 'Web Design Challenges',
            listItems: [
              'Managing visual hierarchy for SEO',
              'Optimizing multimedia for UX & performance',
              'Creating smooth navigation for optimization',
              'Balancing SEO with user-centered design'
            ]
          },
          {
            listTitle: 'Design Strategy',
            listItems: [
              'Incorporated intuitive navigation',
              'Designed clear, prominent CTA buttons for conversions',
              'Optimised design for faster loading times',
              'Prioritized responsive design'
            ]
          }
        ]
      }
    },
    {
      title: "Survey2Connect",
      desc: "Research Tech Platform ",
      Industry: "Enterprise",
      link: "/project/survey2connect/",
      firstAlt: "UI UX Design Screen to Create and Build Surveys",
      firstTitle: "Survey Builder UI Screen",
      secondAlt: "UI UX Design to add and customise Questions in Survey Builder",
      secondTitle: "UI Design to Create Questionnaire",
      thirdAlt: "Dashboard Design to Check and Analyse the Survey Status",
      thirdTitle: "UI Dashboard to Analyse Survey",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      images: ["survey.webp", "survey-2.webp", "survey-3.webp"],
      thirdSlide: {
        title: 'Designing for Customer Experience Enterprise Suite',
        des: `We used different user research techniques to understand user challenges and developed design solutions that enhanced their overall customer experience. With a design-first approach, we have simplified enterprises' complex workflows, assisting them in making informed decisions. `,
        list: [
          {
            listTitle: 'Enterprise Design Challenge',
            listItems: [
              'Balancing complexity with user-friendliness',
              'Integrating diverse systems seamlessly',
              'Ensuring scalability with usability',
              'Accommodating customization needs'
            ]
          },
          {
            listTitle: 'Design Plan',
            listItems: [
              'Introduced customizable workflow templates',
              'Provided personalised dashboards',
              'Emphasized scalability in design architecture',
              'Used visual cues for task navigation'
            ]
          }
        ]
      }
    },
  ];
  const serviceContent = [
    {
      title: "User Research",
      content: [
        "Octet uses diverse data-gathering techniques and conducts thorough user research to ensure your product addresses the correct user problems.",
        'Know more about our <a href="/user-research/" title="User Research Services" > User Research Services</a>',
      ],
      link: "/user-research/",
    },
    {
      title: "UI UX Designing",
      content: [
        "We systematically create visually attractive, interactive interfaces that deliver seamless, intuitive user experiences.",
        'Learn more about our <a href="/ui-ux-designing/" title="UI UX Designing Services">UI UX Designing Services</a>',
      ],
      link: "/ui-ux-designing/",
    },
    {
      title: "Usability Testing",
      content: [
        "We conduct tests with users for various use cases, scenarios, and devices to improve product consistency and accessibility.",
        'See more about our <a href="/usability-testing/" title="Usability Testing Services"> Usability Testing Services</a>',
      ],
      link: "/usability-testing/",
    },
    {
      title: "UI Development",
      content: [
        "We carefully convert designs into templates by building visual components in the Java framework for a delightful user experience.",
        'Know more about our <a href="/ui-development/" title="UI Development Services" >UI Development Service</a>',
      ],
      link: "/ui-development/",
    },
  ];
  const faqData = [
    {
      para: [
        `Octet follows a flexible approach for our <a target="_blank" title="UI UX Design Projects" rel="noreferrer" href="/projects/">UI UX Design Projects</a>,  which helps us adapt to any industry, project type, and collaboration model.`,
        `In our <a target="_blank" title="UI UX Design Process" rel="noreferrer" href="/process/">UI UX design process</a>, we thoroughly understand our client’s goals, challenges, and targeted audience. We then focus on creating intuitive and visually appealing interfaces, prioritizing functionality and accessibility.`,
        `We refine our designs through iterative and usability testing to ensure a seamless user experience.`,
        `Octet focuses on open collaboration and communication with our clients throughout the process to deliver impactful digital experiences that meet their user needs and business objectives.`,
        `However, we always follow certain phases to meet our client’s requirements and offer exceptional experience.`
      ],
      list: [
        `<span><h4 class="inline">Listening and Understanding</h4></span> - We start by understanding your product and the challenges that you are facing to define the nature of our collaboration.`,
        `<span><h4 class="inline">Identifying the Challenges</h4></span> - Based on our discussions, we start our research, explore and identify the market, and gather data using various techniques.`,
        `<span><h4 class="inline">UX Strategy and Information Architecture</h4></span> - Once we have all the data, we start planning the UX flow, creating guidelines, and structuring information architecture to consider your business goals.`,
        `<span><h4 class="inline">Sketching and Wire-Framing Process</h4></span> - We then produce visuals of your idea and create elements and a hierarchy to understand the functions and steps of your product.`,
        `<span><h4 class="inline">UI Design, Prototype and Testing</h4></span> - Once the layout and designs are approved, we create a prototype to test the final product's functionality and interactivity.`,
      ]
    },
    {
      para: [
        `We are a go-to UI UX Design Company for businesses looking for a faster turnaround with a unique blend of creativity, innovation, and client-focused approach. `,
        `Unlike many design firms, we prioritize deep collaboration and open communication with our clients, ensuring their objectives are at the forefront of every project.`,
        `Our team is known for its ability to deliver experiences using cutting-edge design principles and a deep understanding of user behavior. This results in solutions that look visually appealing and drive meaningful results.`,
        `Additionally, our agile and iterative approach allows us to adapt quickly to changing requirements and deliver high-quality designs on time.`,
      ],
      list: [
        `<span><h4 class="inline">Client-centric Approach</h4></span> - We deliver solutions that align with our client's business goals and requirements. This includes understanding their product and its audience and then designing the product. `,
        `<span><h4 class="inline">Collaborative Approach</h4></span> - We take continuous feedback from our clients and iterate the designs until they are unsatisfied with the end product.`,
        `<span><h4 class="inline">Transparency</h4></span> - We strive to achieve an honest workspace by being open about the project’s progress and business operations.`,
        `<span><h4 class="inline">Strategic Value</h4></span> - We focus on providing our clients with long-term beneficial strategies by aligning resources and capabilities with market trends.`,
      ],
    },
    {
      para: [
        `Octet is one of the top UI UX design agency dedicated to creating user-centric designs because we understand that your product’s success depends on the user experience of your application.`,
        `We offer the following range of UI UX design services for your SaaS, B2B, and Enterprise-Grade products-`,
      ],
      list: [
        `<span><h4 class="inline">User Research</h4></span> - With our <a title="user research services" target="_blank" rel="noreferrer" href="/user-research/">user research services</a>, we offer comprehensive insight into your users’ behaviors and preferences and empower your business to make informed decisions tailored to the target audience. `,
        `<span><h4 class="inline">UI UX Design</h4></span> - With our <a title="UI UX design services" target="_blank" rel="noreferrer" href="/ui-ux-designing/">UI UX design services</a> we create intuitive and visually captivating interfaces tailored to enhance user satisfaction and engagement.`,
        `<span><h4 class="inline">Usability Testing</h4></span> - Under the <a title="usability testing services" target="_blank" rel="noreferrer" href="/usability-testing/">usability testing services</a>, we conduct rigorous methodologies to identify usability issues and offer actionable recommendations for optimizing user experience and driving engagement.`,
        `<span><h4 class="inline">UI Development</h4></span> - With our  <a title="UI development services" target="_blank" rel="noreferrer" href="/ui-development/">UI development services</a>, we transform design concepts into pixel-perfect user interfaces. With expertise in front-end technologies, we ensure seamless integration for enhanced user experiences.`,
      ],
      para2: [
        `In addition to the abovementioned services, we offer various other <a title="UI UX Services" target="_blank" rel="noreferrer" href="/services/">UI UX design services</a>.`,
        `With our end-to-end services, experienced team, collaborative approach, and focus on client satisfaction, we deliver exceptional user experiences and drive outstanding business results for you.`,
      ],
    },
    {
      para: [
        `Octet leverages a comprehensive toolkit of industry-leading design tools to ensure efficiency, collaboration, and high-quality outcomes. The tools that we use are:`,
      ],
      list: [
        `<span><h4 class="inline">Figma</h4></span> - <a title="Figma" target="_blank" rel="noreferrer" href="https://www.figma.com/">Figma</a> is a versatile cloud-based design platform facilitating real-time collaboration, prototyping, and design system management. Its collaborative features make it ideal for our distributed teams working on the same <a title="UI UX projects" target="_blank" rel="noreferrer" href="/projects/">UI UX projects</a>. `,
        `<span><h4 class="inline">Adobe Creative Suite</h4></span> - We also utilize various <a title="Adobe Creative Suite" target="_blank" rel="noreferrer" href="https://www.adobe.com/creativecloud.html">Adobe Creative Suite</a> applications such as <a title="Photoshop" target="_blank" rel="noreferrer" href="https://www.adobe.com/in/products/photoshop.html">Photoshop</a> and <a title="Illustrator" target="_blank" rel="noreferrer" href="https://www.adobe.com/in/products/illustrator.html">Illustrator</a> for tasks such as image editing, icon design and graphic creation, which are integral parts of the <a title="UI UX design process" target="_blank" rel="noreferrer" href="/process/">UI UX design process</a>.`,
      ],
      para2: [
        `The abovementioned tools are Octet’s primary design tools to create seamless and user-friendly designs. We also update you with the <a title="latest UI UX tools" target="_blank" rel="noreferrer" href="https://octet.design/most-popular-ui-ux-design-tools/">latest UI UX tools</a> in the industry and use them in your project to deliver exceptional user experiences.`,
      ]
    },
    {
      para: [
        `Yes, the experienced designers at our top UI UX design agency are very flexible and adaptable. We understand the importance of maintaining brand consistency and can work with your existing branding guidelines, design assets, or specific visual elements to create a cohesive and effective design interface.`,
        `Our team is skilled at integrating your established branding and design elements seamlessly into projects.`,
        `By incorporating your branding assets, we ensure that our designs align perfectly with your brand's identity and values, reinforcing brand recognition and strengthening your overall brand presence.`
      ],
    },
    {
      para: [
        `The timeline for a <a title="UI UX project" target="_blank" rel="noreferrer" href="/projects/">UI UX project</a> depends on several key steps to ensure accuracy and efficiency.`,
        `Firstly, we thoroughly analyze your project requirements, including the scope of work, deliverables, and any specific needs.`,
        `Once we understand the project scope and tasks involved, we assess the time required for each task based on our team's expertise and experience with similar projects.`,
        `To create a realistic timeline, we account for potential challenges, revisions, and feedback cycles.`,
        `We also collaborate with our clients throughout the estimation process to align expectations, ensure transparency, and communicate our estimated timeline clearly, highlighting key milestones and dependencies.`,
        `By following this structured approach and leveraging our expertise, we strive to deliver UI UX projects on time and within budget while meeting your expectations.`,
        `On average, a project takes a few weeks to several months, depending on factors such as the size and complexity of your project.`,
        `As the best UI UX design company, we never compromise on the quality of our design and strive to deliver high-quality projects within a reasonable timeframe.`
      ],
    },
    {
      para: [
        `Our UI UX design company offers dedicated project designers depending on our client’s business requirements.`,
        `Our team of experienced designers specializes in creating aesthetically pleasing and user-friendly designs.`,
        `They ensure their design meets your business requirements and reflects our client’s brand identity.`,
        `Additionally, our dedicated designer collaborates throughout the design process and provides recommendations and insights to improve the design of their product.`,
        `They will be responsible for conceptualizing, creating, and refining the design solutions tailored to the business’s specific needs, ensuring that every aspect of the project meets their expectations and aligns with the vision.`,
        `With a dedicated designer leading the project, our clients can expect seamless communication, timely updates, and a high level of commitment to get outstanding results that exceed their expectations.`,
        `<a target="_blank" title="Contact Us" rel="noreferrer" href="/contact-us/">Contact us</a> today to employ UI UX designers who will work exclusively for your project.`,
      ],
    },
    {
      para: [
        `We understand that cost is an essential factor for our clients. That’s why we offer flexible pricing options based on the business requirements.`,
        `However, the cost also depends on the scope and complexity of each project, the number of designers required, the timeline of the project, etc.`,
        `As the best UI UX design company, we believe in transparent pricing and provide detailed project estimates outlining all costs.`,
        `Please <a target="_blank" title="Contact Us" rel="noreferrer" href="/contact-us/">contact us</a> for a detailed cost estimate. We look forward to assisting you.`,
      ],
    },
  ];
  const tabs = [
    {
      link: "/user-research/",
      text: "User Research",
    },
    {
      link: "/usability-testing/",
      text: "Usability Testing",
    },
    {
      link: "/ui-ux-designing/",
      text: "UI UX Designing",
    },
    {
      link: "/ui-development/",
      text: "UI Development",
    },
    {
      link: '/hire-ui-ux-designers/',
      text: 'UI UX Designer'
    },
    {
      link: '/hire-saas-designers/',
      text: 'SaaS Designer'
    },
    {
      link: '/hire-hotjar-experts/',
      text: 'Hotjar Research Experts'
    },
    {
      link: '/hire-conversion-rate-optimisation-experts/',
      text: 'CRO Experts'
    },
    {
      link: '/hire-ux-researchers/',
      text: 'UX Researchers'
    },
    {
      link: '/hire-web-designers/',
      text: 'Web Designer'
    },
    {
      link: '/hire-mobile-app-designers/',
      text: 'Mobile App Designer'
    },
    {
      link: '/hire-webflow-developers/',
      text: 'Webflow Developer'
    },
    {
      link: '/hire-gatsbyjs-developers/',
      text: 'GatsbyJs Developer'
    },
    {
      link: '/hire-reactjs-developers/',
      text: 'ReactJs Developer'
    },
    {
      link: '/hire-angularjs-developers/',
      text: 'AngularJs Developer'
    },
    {
      link: '/hire-vuejs-developers/',
      text: 'VueJs Developer'
    },
    {
      link: '/hire-nextjs-developers/',
      text: 'NextJs Developer'
    },
    {
      link: '/hire-nuxtjs-developers/',
      text: 'NuxtJs Developer'
    }
  ];
  const lastLength = projectData;
  const tabsData = {
    title: "Reshape Industries with <br/>Top UI UX Design Company",
    des: `As the best UI UX design company, our extensive experience and client-focused approach drive sustainable business outcomes for the SaaS, B2B, and Enterprise sectors.`,
    tabs : ["Fintech", "HealthTech", "LogisticsTech", "MarTech", 'Manufacturing'],
    slides: [
      {
        title: 'Fintech',
        desc1: "From digital banking to investment platforms, we have designed user-friendly and intuitive solutions for FinTech.",
        desc2: 'Being the best UI UX design agency, we have experience simplifying complex financial information simply and understandably.',
        listTile: 'Our Offerings',
        list: [
          'Seasoned in user-centric financial interface design',
          'Well-versed in fintech regulations and compliance',
          'Proven record in financial app development'
        ]
      },
      {
        title: 'HealthTech',
        desc1: "We have experience working with different health tech domains and understand their unique challenges and considerations.",
        desc2: 'Our top UI UX design company’s design solutions, from EHRs and EMRs to telemedicine platforms and more, have facilitated health tech businesses in smooth and unified management.',
        listTile: 'Our Contributions',
        list: [
          'Expertise in specialized medical software interface',
          'Deep understanding of healthcare industry standards',
          'Developing sophisticated medical data-sharing platforms'
        ]
      },
      {
        title: 'LogisticsTech',
        desc1: "We have enhanced logistics efficiency by designing custom platforms that provide real-time updates for fleet management, e-commerce deliveries, and warehouse management.",
        desc2: 'Our UI UX design services help you to manage deliveries and optimize logistics.',
        listTile: 'Our Deliveries',
        list: [
          'Simplifying complex logistics',
          'Designing intuitive dashboards for real-time monitoring',
          'Providing ongoing support for seamless operations'
        ]
      },
      {
        title: 'MarTech',
        desc1: "Octet is one of the best UI UX design agency with hands-on experience creating functional and visually appealing dashboards.",
        desc2: `Our design solutions and data visualization techniques have streamlined businesses' campaign management processes and improved productivity.`,
        listTile: 'Our Solutions',
        list: [
          'Flexibly adapting to changing consumer trends',
          'Mastering brand identity through design',
          'Dynamic, interactive content creation capabilities'
        ]
      },
      {
        title: 'Manufacturing',
        desc1: "We have worked with various manufacturing industries and understand their complexities.",
        desc2: 'Our UI UX design company has optimized and streamlined workflows, helping brands manage their products efficiently.',
        listTile: 'Our Services',
        list: [
          'Leveraging design expertise in smart manufacturing',
          'Deep understanding of customizable manufacturing solutions',
          'Adept in crafting facility layout designs'
        ]
      }
    ]
  };
  const tabsData1 = {
    title: `Drive Impact with <span class='h1-span'>Top UI UX Design Company</span>`,
    des: `With our UI UX design company, proficient design approach, unique UI UX design services, and a track record of successfully designing 200+ digital products, we deliver experiences that align with your business goals.`,
    tabs : ["SaaS UX Designing ", "Enterprise Designing", "Research-Driven Designing", "Design Thinking"],
    slides: [
      {
        title: 'SaaS UX Designing ',
        desc1: "We have simplified the complex processes of 20+ SaaS platforms by analyzing their user requirements and market trends.",
        desc2: 'With our SaaS designing solutions, we have helped businesses scale and improve their user satisfaction.',
        listTile: 'Our designers',
        list: [
          'Use data-driven insights to create SaaS solutions',
          'Prioritise user needs for optimized usability',
          'Design scalable solutions for product growth'
        ]
      },
      {
        title: 'Enterprise Designing',
        desc1: "Our UI UX design agency has served the dynamic needs of 15+ Enterprises and provided them with tailored solutions with our agile methodologies.",
        desc2: 'With our Enterprise UX Designing, we help large-scale businesses drive tangible results.',
        listTile: 'Our experts',
        list: [
          'Tailored solutions for enterprise needs',
          'Created streamlined workflow for efficiency',
          'Customized interfaces for diverse requirements'
        ]
      },
      {
        title: 'Research-Driven Designing',
        desc1: "With our deep understanding of user research methodologies and a problem-solving approach, our UI UX design company delivers digital solutions to 80+ clients.",
        desc2: 'We aim to uncover hidden problems and opportunities to offer effective user-centered design solutions.',
        listTile: 'Services include',
        list: [
          'Conduct in-depth analysis to understand user behavior',
          'Refine designs through iterative user feedback',
          'Rigorous testing to ensure an intuitive user experience'
        ]
      },
      {
        title: 'Design Thinking',
        desc1: "We provide innovative design solutions while keeping user preferences and requirements at the forefront.",
        desc2: 'With our practical and creative approach, we provide UI UX design services to promote cross-functional collaboration within the business.',
        listTile: 'Solutions entail',
        list: [
          'Understand user requirements through empathetic research',
          'Design innovative solutions to address user pain points',
          'Focus on user-centric designs for meaningful experiences'
        ]
      }
    ]
  };
  const tabsData2 = {
    title: 'Blogs & Insights',
    des: `Discover the trending design trends, technologies, and insights through our curated blog section. Stay updated on UI UX with our expert guides and industry analysis.`,
    tabs : ["Featured", "Stories", "Collections"],
    slides: [1,2,3]
  }
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "How do you approach your UI UX Design project?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet follows a flexible approach for our UI UX Design Projects, which helps us adapt to any industry, project type, and collaboration model. 

          In our UI UX Design process, we thoroughly understand our client’s goals, challenges, and targeted audience. We then focus on creating intuitive and visually appealing interfaces, prioritizing functionality and accessibility. 

          We refine our designs through iterative and usability testing to ensure a seamless user experience. 

          Octet focuses on open collaboration and communication with our clients throughout the process to deliver impactful digital experiences that meet their user needs and business objectives.

          However, we always follow certain phases to meet our client’s requirements and offer exceptional experience.
          Listening and Understanding- 
          We start by understanding your product and the challenges that you are facing to define the nature of our collaboration.
          Identifying the Challenges- 
          Based on our discussions, we start our research, explore and identify the market, and gather data using various techniques.
          UX Strategy and Information Architecture- 
          Once we have all the data, we start planning the UX flow, creating guidelines, and structuring information architecture to consider your business goals.
          Sketching and Wire-Framing Process- 
          We then produce visuals of your idea and create elements and a hierarchy to understand the functions and steps of your product.
          UI Design, Prototype, and Testing- 
          Once the layout and designs are approved, we create a prototype to test the final product's functionality and interactivity."
              }
            },{
              "@type": "Question",
              "name": "How does Octet’s UI UX Design Agency differ from other companies?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We are a go-to UI UX Design Company for businesses looking for a faster turnaround with a unique blend of creativity, innovation, and client-focused approach. 

          Unlike many design firms, we prioritize deep collaboration and open communication with our clients, ensuring their objectives are at the forefront of every project. 

          Our team is known for its ability to deliver experiences using cutting-edge design principles and a deep understanding of user behavior. This results in solutions that look visually appealing and drive meaningful results. 

          Additionally, our agile and iterative approach allows us to adapt quickly to changing requirements and deliver high-quality designs on time. 
          Client-centric Approach
          We deliver solutions that align with our client's business goals and requirements. This includes understanding their product and its audience and then designing the product. 
          Collaborative Approach
          We take continuous feedback from our clients and iterate the designs until they are unsatisfied with the end product. 
          Transparency
          We strive to achieve an honest workspace by being open about the project’s progress and business operations.
          Strategic value
          We focus on providing our clients with long-term beneficial strategies by aligning resources and capabilities with market trends."
              }
            },{
              "@type": "Question",
              "name": "What UI UX design services does your UI UX Design Agency offer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet is one of the top UI UX design agency dedicated to creating user-centric designs because we understand that your product’s success depends on the user experience of your application. 

          We offer the following range of UI UX design services for your SaaS, B2B, and Enterprise-Grade products- 
          User Research
          With our user research services, we offer comprehensive insight into your users’ behaviors and preferences and empower your business to make informed decisions tailored to the target audience. 
          UI UX Design
          With our UI UX design services, we create intuitive and visually captivating interfaces tailored to enhance user satisfaction and engagement. 
          Usability Testing
          Under the usability testing services, we conduct rigorous methodologies to identify usability issues and offer actionable recommendations for optimizing user experience and driving engagement. 
          UI Development 
          With our UI development services, we transform design concepts into pixel-perfect user interfaces. With expertise in front-end technologies, we ensure seamless integration for enhanced user experiences.

          In addition to the abovementioned services, we offer various other UI UX design services. 

          With our end-to-end services, experienced team, collaborative approach, and focus on client satisfaction, we deliver exceptional user experiences and drive outstanding business results for you."
              }
            },{
              "@type": "Question",
              "name": "What design tools do you use for UI UX design projects?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet leverages a comprehensive toolkit of industry-leading design tools to ensure efficiency, collaboration, and high-quality outcomes. The tools that we use are:
          Figma
          Figma is a versatile cloud-based design platform facilitating real-time collaboration, prototyping, and design system management. Its collaborative features make it ideal for our distributed teams working on the same UI UX projects.
          Adobe Creative Suite
          We also utilize various Adobe Creative Suite applications, such as Photoshop and Illustrator, for tasks such as image editing, icon design, and graphic creation, which are integral to the UI UX design process.

          The abovementioned tools are Octet’s primary design tools to create seamless and user-friendly designs. We also update you with the latest UI UX tools in the industry and use them in your project to deliver exceptional user experiences."
              }
            },{
              "@type": "Question",
              "name": "Can your top UI UX design company work with existing branding & design elements?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, the experienced designers at our top UI UX design agency are very flexible and adaptable. We understand the importance of maintaining brand consistency and can work with your existing branding guidelines, design assets, or specific visual elements to create a cohesive and effective design interface. 

          Our team is skilled at integrating your established branding and design elements seamlessly into projects. 

          By incorporating your branding assets, we ensure that our designs align perfectly with your brand's identity and values, reinforcing brand recognition and strengthening your overall brand presence."
              }
            },{
              "@type": "Question",
              "name": "How do you estimate the UI UX project timeline?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The timeline for a UI UX project depends on several key steps to ensure accuracy and efficiency. 

          Firstly, we thoroughly analyze your project requirements, including the scope of work, deliverables, and any specific needs.
          
          Once we understand the project scope and tasks involved, we assess the time required for each task based on our team's expertise and experience with similar projects. 

          To create a realistic timeline, we account for potential challenges, revisions, and feedback cycles.

          We also collaborate with our clients throughout the estimation process to align expectations, ensure transparency, and communicate our estimated timeline clearly, highlighting key milestones and dependencies. 

          By following this structured approach and leveraging our expertise, we strive to deliver UI UX projects on time and within budget while meeting your expectations.

          On average, a project takes a few weeks to several months, depending on factors such as the size and complexity of your project. 

          As the best UI UX design company, we never compromise on the quality of our design and strive to deliver high-quality projects within a reasonable timeframe."
              }
            },{
              "@type": "Question",
              "name": "Does your UI UX Design Company offer a dedicated Designer for projects?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our UI UX design company offers dedicated project designers depending on our client’s business requirements. 

          Our team of experienced designers specializes in creating aesthetically pleasing and user-friendly designs. 

          They ensure their design meets your business requirements and reflects our client’s brand identity. 

          Additionally, our dedicated designer collaborates throughout the design process and provides recommendations and insights to improve the design of their product.  

          They will be responsible for conceptualizing, creating, and refining the design solutions tailored to the business’s specific needs, ensuring that every aspect of the project meets their expectations and aligns with the vision. 

          With a dedicated designer leading the project, our clients can expect seamless communication, timely updates, and a high level of commitment to get outstanding results that exceed their expectations. 

          Contact us today to employ UI UX designers who will work exclusively for your project."
              }
            },{
              "@type": "Question",
              "name": "What is the standard pricing followed by your best UI UX design company?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We understand that cost is an essential factor for our clients. That’s why we offer flexible pricing options based on the business requirements. 

          However, the cost also depends on the scope and complexity of each project, the number of designers required, the timeline of the project, etc. 

          As a best UI UX design company, we believe in transparent pricing and provide detailed project estimates outlining all costs. 

          Please contact us for a detailed cost estimate. We look forward to assisting you."
              }
            }]
          }
                     
        `}
      </script>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Octet Design Studio",
            "logo": "https://octet.design/static/30e06da1725c0ede88aaf2235131e1f7/bc87c/octet-logo.webp",
            "url": "https://octet.design/",
            "description": "Octet is a leading UI UX Design Company headquartered in Ahmedabad. Our expertise lies in simplifying complex products through modern and user-friendly design solutions. We are highly result-driven professionals and our success is attributed to our prudent and proactive approach.",
            "founder": [{
              "@type": "Person",
              "name": "Aakash Jethwani"
            }],
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "306",
              "addressLocality": "North Plaza, Motera",
              "addressRegion": "Ahmedabad",
              "postalCode": "380005",
              "addressCountry": "India"
            },
            "sameAs": [
              "https://dribbble.com/OctetDesignStudio",
              "https://www.linkedin.com/company/13714789/admin/",
              "https://www.instagram.com/octet.design/",
              "https://www.behance.net/OctetDesignStudio"
            ]
          }
        `}
      </script>
      <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js" ></script>
    </Helmet>
      <Layout>
        <Banner content={banner} page={"home"} />
        <section className="project-section lg:py-[200px] md:pb-[125px] md:pt-[140px] pt-[100px] pb-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
          {projectData.map((data, index) => (
            <div key={index}>
              <ProjectSection num={index} lastLength={lastLength} data={data} />
            </div>
          ))}
          <div className="container lg:mt-[-100px] md:mt-[-35px] mt-[-40px]">
            <Link
              title="UI UX Design Portfolio"
              to="/projects/"
              className="animated-link font-medium font-heading text-[20px] md:text-[22px] lg:text-[30px] md:leading-[1.33] leading-[normal] flex items-center gap-[32px] md:gap-[38px]"
            >
              <h2 className="max-w-[262px] normal md:max-w-none w-auto font-heading">
                View All Projects
              </h2>
              <span>
                <LinkArrowSvg />
              </span>
            </Link>
          </div>
        </section>
        <ClientsSection title={'Trusted By Clients Worldwide'} />
        <ServiceTab tabs={tabs} />
        <Services serviceContent={serviceContent} />
        <SwiperTabs data={tabsData1} tabsData1 />
        <Testimonials title={`Client's Take on <span class='h1-span'>Our UI UX Design Services</span>`} />
        <SwiperTabs data={tabsData} />
        <SwiperTabs light recentPosts={recentPosts} recentPosts1={recentPosts1} recentPosts2={recentPosts2} data={tabsData2} />
        <Faq section Questions={Questions} faqData={faqData} des={'Read the FAQs below to learn more about our UI UX design agency. <a title="Contact Us" target="_blank" rel="noreferrer" href="/contact-us/">Contact us</a> if you have more questions about our <a title="UI UX Design Services" target="_blank" rel="noreferrer" href="/services/">UI UX Design Services</a>. '} />
        <ServiceTab tabs={tabs} />
        <TeamSection />
      </Layout>
    </>
  );
};

export const Head = () => (
  <Seo
    title="UI UX Design Agency | Octet Design Studio"
    description="We are a UI UX Design Agency that empowers SaaS, B2B, & enterprises with creative UX solutions, driving digital transformation through a design-first approach."
  />
);

export const pageQuery = graphql`
  query {
    recentQuery: allWpPost(sort: { date: DESC }, limit: 6, filter: {tags: {nodes: {elemMatch: {name: {eq: "featured"}}}}}) {
      edges {
        node {
          title
          excerpt
          slug
          date
          seo {
            readingTime
          }
          featuredImage {
            node {
              gatsbyImage(formats: WEBP, width: 400, placeholder: BLURRED)
              altText
              title
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
          author {
            node {
              name
            }
          }
        }
      }
    }
    recentQueryInspirations: allWpPost(sort: { date: DESC }, limit: 6, filter: {tags: {nodes: {elemMatch: {name: {eq: "stories"}}}}}) {
      edges {
        node {
          title
          excerpt
          slug
          date
          seo {
            readingTime
          }
          featuredImage {
            node {
              gatsbyImage(formats: WEBP, width: 400, placeholder: BLURRED)
              altText
              title
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
          author {
            node {
              name
            }
          }
        }
      }
    }
    recentQueryTutorials: allWpPost(sort: { date: DESC }, limit: 6, filter: {tags: {nodes: {elemMatch: {name: {eq: "collections"}}}}}) {
      edges {
        node {
          title
          excerpt
          slug
          date
          seo {
            readingTime
          }
          featuredImage {
            node {
              gatsbyImage(formats: WEBP, width: 400, placeholder: BLURRED)
              altText
              title
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
          author {
            node {
              name
            }
          }
        }
      }
    }
  }
`;
