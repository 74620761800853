import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import "../../styles/clients.css";

const ClientsSection = ({title}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileQuery = window.matchMedia("(max-width: 1024px)");
      setIsMobile(isMobileQuery.matches);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const dynamicImages = [
    {
      alt: "Visa's Logo- UI UX Design Client",
      title: "Visa",
      images: ["visa.webp"],
      width: { lg: 67.5, md: 59.35 },
    },
    {
      alt: "LodeStone's Logo- UI UX Design Client",
      title: "Lodestone",
      images: ["lode-stone.webp"],
      width: { lg: 91, md: 72.05 },
    },
    {
      alt: "Adani's Logo- UI UX Design Client",
      title: "Adani",
      images: ["adani.webp"],
      width: { lg: 69, md: 63.42 },
    },
    {
      alt: "TVS's Logo- UI UX Design Client",
      title: "TVS",
      images: ["tvs.webp"],
      width: { lg: 102, md: 82.19 },
    },
    {
      alt: "IIM-A's Logo- UI UX Design Client",
      title: "IIM-A",
      images: ["iima.webp"],
      width: { lg: 53, md: 34.95 },
    },
    {
      alt: "Intellect's Logo- UI UX Design Client",
      title: "Intellect",
      images: ["intellect.webp"],
      width: { lg: 116.89, md: 75.65 },
    },
    {
      alt: "Aditya-Birla's Logo- UI UX Design Client ",
      title: "Aditya Birla",
      images: ["aditya-birla.webp"],
      width: { lg: 122, md: 88.68 },
    },
    {
      alt: "Kantime's Logo- UI UX Design Client",
      title: "Kantime",
      images: ["kantime.webp"],
      width: { lg: 73, md: 66.23 },
    },
    {
      alt: "Hushly's Logo- UI UX Design Client ",
      title: "Hushly",
      images: ["hushly-logo.webp"],
      width: { lg: 100, md: 99.65 },
    },
    {
      alt: "Logo's Logo- UI UX Design Client",
      title: "Logo",
      images: ["logo-client.webp"],
      width: { lg: 69, md: 42 },
    },
    {
      alt: "Shoppers Stop's Logo- UI UX Design Client",
      title: "Shoppers Stop",
      images: ["shoppers.webp"],
      width: { lg: 127, md: 95.21 },
    },
    {
      alt: "MIT's Logo- UI UX Design Client",
      title: "MIT",
      images: ["mit-logo.webp"],
      width: { lg: 140, md: 51.52 },
    },
    {
      alt: "Nirvana's Logo- UI UX Design Client",
      title: "Nirvana",
      images: ["nirvana.webp"],
      width: { lg: 129.59, md: 99.6 },
    },
    {
      alt: "Shipdelight's Logo- UI UX Design Client",
      title: "Shipdelight",
      images: ["ship-delight.webp"],
      width: { lg: 149, md: 98.52 },
    },
    {
      alt: "Survey2connect's Logo- UI UX Design Client ",
      title: "Survey2Connect",
      images: ["survey-connect.webp"],
      width: { lg: 170, md: 127.57 },
    },
    {
      alt: "Vet-It's Logo- UI UX Design Client ",
      title: "Vet IT",
      images: ["vet-it-client.webp"],
      width: { lg: 69, md: 45.84 },
    },
  ];

  const imageData = useStaticQuery(graphql`
    query {
      dynamicImages: allFile(
        filter: {
          relativePath: {
            regex: "^(visa|logo-client|talk-back|survey-connect|vet-it|lode-stone|iima|tvs|adani|intellect|aditya-birla|kantime|attentive|ship-delight|kesari|shoppers|nirvana)/"
          }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const imagesByPath = {};
  imageData.dynamicImages.edges.forEach(({ node }) => {
    const imagePath = node.relativePath;
    const imageData = getImage(node.childImageSharp);
    imagesByPath[imagePath] = imageData;
  });

  return (
    <section className="lg:py-[200px] pt-[80px] pb-[106px] md:pt-[120px] md:pb-[120px] overflow-hidden client">
      <div className="container relative">
        <div className="absolute lg:right-[-380px] md:block hidden right-[-177px] top-[-208px] lg:top-[-495px] guides"></div>
        {
          title ? 
          <h2 className="font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]">
            {title}
          </h2> : 
          <h2 className="font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]">
            We’re Strategically Creative <br className="hidden md:block" /> &
            Creatively Strategic{" "}
          </h2>
        }
        
        <div className="border lg:border-0 relative md:mt-[85px] mt-[66px] z-1 client-box lg:mt-0 border-[#1B1B1B] mr-auto ml-auto md:max-w-[688px] lg:max-w-full grid grid-cols-2 md:grid-cols-4 pt-[0px] lg:pt-[109px] lg:pr-[21px]">
          <div className="absolute left-[-25px] top-[81px] z-[-1] guide-client">
            <StaticImage
              placeholder="none"
              src="../../images/guides-3.webp"
              alt="guides"
            />
          </div>
          {dynamicImages.map(({ title, images, width }, index) => {
            return (
              <div
                key={index}
                className="border lg:border-0 border-[#1B1B1B] lg:h-[135px] xl:h-[160px] h-[100px] md:h-[110px] flex items-center justify-center"
              >
                <GatsbyImage
                  style={{ width: isMobile ? width.md : width.lg }}
                  image={imagesByPath[images[0]]}
                  alt={title ? `${title} Comapny Logo` : "client"}
                  title={title}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default ClientsSection;
