import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Services = ({serviceContent}) => {
    return (
        <section className="lg:py-[200px] md:py-[120px] py-[80px]">
            <div className='container'>
                <h2 className='md:mb-[30px] mb-[20px] max-w-[950px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]'>
                    Redefine Experiences with <span className="h1-span">Best UI UX Design Agency</span>
                </h2>
                <p className='lg:mb-[100px] md:mb-[80px] mb-[60px] tracking-[.03em] md:text-[16px] leading-[1.733] md:leading-[1.8750] text-[15px] lg:text-[20px] lg:leading-[1.67] max-w-[800px] md:pr-[0] pr-[20px]'>
                    As the top UI UX design agency, we blend strategy, creativity, and technology to provide customized digital solutions for your SaaS, B2B, and enterprise products. 
                </p>
                {serviceContent.map((content, index) => (
                    <div key={index} className={`service flex gap-[30.5px] lg:items-center flex-col md:flex-row ${index === 3 ? 'mb-0' : 'mb-[60px] md:mb-[80px] lg:mb-[140px]' }`}>
                        <div className='content lg:max-w-[420px] lg:w-[420px] md:max-w-[260px] lg:min-w-[420px]'>
                            <h3 className='mb-[10px] md:text-[28px] text-[20px] lg:text-[35px] font-semibold leading-[1.35] font-heading md:leading-[1.420] lg:leading-[1.5714]'>{content.title}</h3>
                            {content.content.map((innerContent, index) => (
                                <p key={index}  className='lg:mb-[30px] link-text-para service text-[15px] leading-[1.733] mb-[15px] md:mb-[10px] tracking-[.03em] md:text-[16px] lg:text-[18px] md:leading-[1.8750] lg:leading-[1.67] md:pr-[20px] pr-[20px]' dangerouslySetInnerHTML={{ __html: innerContent }}>
                                </p>
                            ))}
                            {/* <Link to={content.link} title={index === 0 ? 'Know More About Users Research Services' : index === 1 ? 'Know More About UI UX Design Services' : index === 2 ? 'Know More About Usability Testing Services' : 'Know More About UI Development Services'}
                                className="md:mt-[40px] mt-[20px]  lg:mt-[10.5px] font-body service-link transition-all hover:duration-300 duration-300 text-[15px] hover:font-medium leading-[1.2] tracking-[.03em] uppercase flex items-center gap-[9px] hover:gap-[20px]"
                            >
                                READ MORE  <span className='rotate-45'><LinkArrowSvg size={15.58} fill='#2A2A2A' /></span>
                            </Link> */}
                        </div>
                        <div className='max-w-[717px]'>
                            {
                                index === 0 ? <StaticImage placeholder="none" src="../../images/service-final-1.webp" alt="Gathering Users Insight- A part of our User Research Services" title="User Research" className={`service-image`} /> :
                                index === 1 ? <StaticImage placeholder="none"  src="../../images/service-final-2.webp" alt="Creating Visually Interactive UI UX Interface" title='UI UX Design' className={`service-image`} /> :
                                index === 2 ? <StaticImage placeholder="none"  src="../../images/service-final-3.webp" alt="Identifying Usability Issues- Part of Usability Testing Services" title="Usability Testing" className={`service-image`} /> :
                                <StaticImage placeholder="none"  src="../../images/service-final-4.webp" alt="Converting Figma Design into Javascript- Part of UI Development Services" title='UI Development' className={`service-image`} />
                            }
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}
export default Services
